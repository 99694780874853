import { memo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import Text from '../../components/Text'
import { COLORS } from '../../utils/colorUtils'

const Container = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 14px 18px;
  border-radius: 8px;
  background-color: ${COLORS.white};
`

const Figure = styled.figure`
  flex-shrink: 0;
  width: 85px;
  height: 80px;
  border-radius: 8px;
  background-color: ${COLORS.black};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px 0 26px;
  white-space: nowrap;
`

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 5rem;
  overflow: hidden;
`

const top = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
`

const bottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  50% {
    opacity: 0;
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
`

const NumberTextTop = styled(Text)`
  font-size: 3.8rem;
  animation: ${top} 800ms ease forwards;
`

const NumberTextBottom = styled(Text)`
  font-size: 3.8rem;
  animation: ${bottom} 800ms ease forwards;
`

function Counter({ number, caption }) {
  const prevNumber = useRef(0)

  useEffect(() => {
    prevNumber.current = number
  }, [number])

  return (
    <Container>
      <Figure />
      <Content>
        <NumberWrapper key={number}>
          <NumberTextTop weight="bold">
            {prevNumber.current.toLocaleString()}
          </NumberTextTop>
          <NumberTextBottom weight="bold">
            {number.toLocaleString()}
          </NumberTextBottom>
        </NumberWrapper>
        <Text size="increased">{caption}</Text>
      </Content>
    </Container>
  )
}

Counter.propTypes = {
  number: PropTypes.number,
  caption: PropTypes.string.isRequired,
}

Counter.defaultProps = {
  number: 0,
}

export default memo(Counter)

import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { COLORS } from '../../utils/colorUtils'
import Text from '../../components/Text'
import { BREAKPOINT } from '../app/constants'

import Quote from './Quote'

const Container = styled.div`
  z-index: 1;
  position: relative;
  padding: 16px 31px 37px;
  border-radius: 8px;
  background-color: ${COLORS.black};

  @media (min-width: ${BREAKPOINT.large}px) {
    top: -40px;
  }
`

const Top = styled.div`
  width: 100%;
  height: 70px;
`

const QuoteSign = styled(Text)`
  font-size: 14.4rem;
  color: ${COLORS.white};
`

const Bottom = styled.div`
  display: flex;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-basis: 45%;
  align-items: flex-end;
`

const Title = styled(Text)`
  font-size: 2.4rem;
  color: ${COLORS.white};
`

const QuoteList = styled.div`
  flex-grow: 1;
  flex-basis: 55%;
  position: relative;
  top: 60px;
`

export default function Quotes() {
  const { t } = useTranslation('landing')

  return (
    <Container>
      <Top>
        <QuoteSign>&ldquo;</QuoteSign>
      </Top>
      <Bottom>
        <TitleWrapper>
          <Title forwardedAs="h2" weight="bold">
            {t('whatUsersSayUs')}
          </Title>
        </TitleWrapper>
        <QuoteList>
          <Quote name="Vanessa" quote={t('helpedMeWithResume')} />
          <Quote name="Johanna" quote={t('gotJob')} />
          <Quote name="Erik" quote={t('gotControl')} />
        </QuoteList>
      </Bottom>
    </Container>
  )
}

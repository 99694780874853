import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import styled, { keyframes, css } from 'styled-components'

import Column from '../../components/Column'
import Grid from '../../components/Grid'
import Text from '../../components/Text'
import { BREAKPOINT } from '../app/constants'
import { COLORS } from '../../utils/colorUtils'
import useInView from '../../hooks/useInView'

import FunctionsCarousel from './FunctionsCarousel'

const Container = styled.section`
  padding: 40px 0 30px;

  @media (min-width: ${BREAKPOINT.medium}px) {
    padding: 140px 0 90px;
  }
`

const LeftColumn = styled(Column)`
  display: flex;
  justify-content: center;

  @media (min-width: ${BREAKPOINT.medium}px) {
    display: block;
  }
`

const Illustration = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;

  @media (min-width: ${BREAKPOINT.medium}px) {
    width: auto;
  }
`

const Content = styled.div`
  padding: 42px 0 0 0;

  @media (min-width: ${BREAKPOINT.medium}px) {
    padding: 20px 0 0 30px;
  }

  @media (min-width: ${BREAKPOINT.large}px) {
    padding: 72px 0 0 30px;
  }
`

const Header = styled.header`
  margin-bottom: 24px;
`

const List = styled.ul`
  padding-top: 28px;
`

const opacity = keyframes`
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`

const scale = keyframes`
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2857142857);
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 27px;
  opacity: 0.5;

  ${props =>
    props.active
      ? css`
          animation: ${opacity} 1000ms ease-out forwards;
        `
      : ''};
`

const ListItemText = styled.span`
  font-size: 1.4rem;
  line-height: 1.4em;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  transform-origin: 0% 50%;
  ${props =>
    props.active
      ? css`
          animation: ${scale} 300ms ease-out forwards;
        `
      : ''};
`

const ListItemBullet = styled.span`
  width: 12px;
  height: 12px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: ${COLORS.black};
`

export default function Functions() {
  const { t } = useTranslation('landing')

  const [step, setStep] = useState(1)
  const { ref, inView } = useInView()

  const timerRef = useRef(null)

  useEffect(() => {
    if (inView) {
      timerRef.current = setTimeout(
        () => setStep(step === 3 ? 1 : step + 1),
        3000,
      )
    } else if (timerRef.current !== null) {
      clearTimeout(timerRef.current)
    }
  }, [step, inView])

  useEffect(() => {
    const { current: timer } = timerRef

    return () => {
      if (timer !== null) {
        clearTimeout(timer)
      }
    }
  }, [])

  return (
    <Container ref={ref}>
      <Grid pad>
        <LeftColumn md={6}>
          <Illustration>
            <FunctionsCarousel step={step} />
          </Illustration>
        </LeftColumn>
        <Column md={6}>
          <Content>
            <Header>
              <Text as="h1" size="giant" weight="bold">
                {t('ourBestFunctions')}
              </Text>
            </Header>
            <Text size="large">{t('ourBestTexts')}</Text>
            <List>
              <ListItem active={step === 1}>
                <ListItemBullet />
                <ListItemText active={step === 1}>
                  {t('accessToUnique')}
                </ListItemText>
              </ListItem>
              <ListItem active={step === 2}>
                <ListItemBullet />
                <ListItemText active={step === 2}>
                  {t('accessToJobs')}
                </ListItemText>
              </ListItem>
              <ListItem active={step === 3}>
                <ListItemBullet />
                <ListItemText active={step === 3}>
                  {t('connectWithCommunity')}
                </ListItemText>
              </ListItem>
            </List>
          </Content>
        </Column>
      </Grid>
    </Container>
  )
}

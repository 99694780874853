import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import Column from '../../components/Column'
import Grid from '../../components/Grid'
import Text from '../../components/Text'
import { COLORS } from '../../utils/colorUtils'
import { BREAKPOINT } from '../app/constants'

import Quotes from './Quotes'

const Container = styled.section`
  padding: 40px 0 60px;

  @media (min-width: ${BREAKPOINT.medium}px) {
    padding: 40px 0 130px;
  }
`

const Content = styled.div`
  padding-left: 32px;
  padding-right: 32px;

  @media (min-width: ${BREAKPOINT.medium}px) {
    padding-right: 0;
  }
`

const StyledGrid = styled(Grid)`
  flex-direction: column-reverse !important;

  @media (min-width: ${BREAKPOINT.medium}px) {
    flex-direction: row !important;
  }
`

const Header = styled.header`
  z-index: 1;
  position: relative;
  margin-bottom: 24px;
`

const Title = styled(Text)`
  font-size: 3.2rem;

  @media (min-width: ${BREAKPOINT.medium}px) {
    font-size: 4.8rem;
  }
`

const Decoration = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -32px;
  width: 8px;
  border-radius: 4px;
  background-color: ${COLORS.paleSlate};
`

const Paragraph = styled(Text)`
  margin-bottom: 24px;
  opacity: 0.5;

  @media (min-width: ${BREAKPOINT.large}px) {
    max-width: 440px;
  }
`

const Squares = styled.div`
  display: flex;
  justify-content: space-between;
  width: 117px;
`

const Square = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 8px;
  background-color: ${COLORS.paleSlate};
`

const QuotesWrapper = styled.div`
  margin-bottom: 50px;

  @media (min-width: ${BREAKPOINT.large}px) {
    margin-bottom: 0;
  }
`

export default function Reviews() {
  const { t } = useTranslation('landing')

  return (
    <Container>
      <StyledGrid pad>
        <Column md={6}>
          <Content>
            <Header>
              <Title forwardedAs="h1" weight="bold">
                {t('whatUsersSayPlatform')}
              </Title>
              <Decoration />
            </Header>
            <Paragraph size="large" weight="bold">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
              dolor sed molestie purus, ultrices egestas nisi, semper aenean.
              Posuere in lectus sit cras sodales lectus urna euismod. Nulla nibh
              diam, a scelerisque dui dapibus pretium
            </Paragraph>
            <Squares>
              <Square />
              <Square />
              <Square />
            </Squares>
          </Content>
        </Column>
        <Column md={6}>
          <QuotesWrapper>
            <Quotes />
          </QuotesWrapper>
        </Column>
      </StyledGrid>
    </Container>
  )
}

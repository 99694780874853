import { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import NetworkOfContactsImage from '../../assets/images/network-of-contacts.svg'
import AdvertisementsImage from '../../assets/images/advertisements.svg'
import ResumeImage from '../../assets/images/resume.svg'
import { COLORS } from '../../utils/colorUtils'

const Container = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  max-width: 445px;
  padding-top: 113.93258427%;
`

const Figure = styled.figure`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 507px;

  & svg {
    position: absolute;
  }

  & .primary,
  & .secondary {
    will-change: fill;
    transition: fill ease-out 1000ms;
  }
`

const FigCaption = styled.figcaption`
  z-index: -1;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
`

const NetworkOfContacts = styled(NetworkOfContactsImage)`
  z-index: ${props => (props.$active ? 3 : 1)};
  top: 0;
  right: 0;
  width: 59.5505618%;
  height: 52.46548323%;

  & .primary {
    fill: ${props => (props.$active ? COLORS.black : COLORS.iron)};
  }

  & .secondary {
    fill: ${props => (props.$active ? COLORS.paleSlate : COLORS.secondary)};
  }
`

const Advertisements = styled(AdvertisementsImage)`
  z-index: ${props => (props.$active ? 3 : 1)};
  right: 0;
  bottom: 0;
  width: 72.13483146%;
  height: 40.63116371%;

  & .primary {
    fill: ${props => (props.$active ? COLORS.black : COLORS.secondary)};
  }
`

const Resume = styled(ResumeImage)`
  z-index: ${props => (props.$active ? 3 : 2)};
  top: 16.765286%;
  width: 55.73033708%;
  height: 72.38658777%;
  left: 0;

  & .primary {
    fill: ${props => (props.$active ? COLORS.black : COLORS.secondary)};
  }

  & .secondary {
    fill: ${props => (props.$active ? COLORS.paleSlate : COLORS.white)};
  }
`

function FunctionsCarousel({ step }) {
  const { t } = useTranslation('landing')

  return (
    <Container>
      <Figure>
        <Resume $active={step === 1} />
        <Advertisements $active={step === 2} />
        <NetworkOfContacts $active={step === 3} />
        <FigCaption>{t('functionsFigCaption')}</FigCaption>
      </Figure>
    </Container>
  )
}

FunctionsCarousel.propTypes = {
  step: PropTypes.number.isRequired,
}

export default memo(FunctionsCarousel)

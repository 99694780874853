import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import Oval from '../../assets/images/oval.svg'
import Grid from '../../components/Grid'
import Column from '../../components/Column'
import Text from '../../components/Text'
import { COLORS } from '../../utils/colorUtils'
import { BREAKPOINT } from '../app/constants'
import Button from '../../components/Button'

import Counters from './Counters'

const Container = styled.section`
  z-index: 1;
  position: relative;
  width: 100%;
  padding-bottom: 9.2vw;
  background-color: ${COLORS.secondary};
`

const StyledOval = styled(Oval)`
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
`

const StyledColumn = styled(Column)`
  display: flex;
  justify-content: center;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  padding: 70px 0;

  @media (min-width: ${BREAKPOINT.small}px) {
    padding: 165px 0 120px;
  }

  @media (min-width: ${BREAKPOINT.medium}px) {
    padding: 165px 0 120px;
  }
`

const Caption = styled(Text)`
  margin-bottom: 32px;
  font-size: 4.8rem;

  @media (min-width: ${BREAKPOINT.medium}px) {
    margin-bottom: 50px;
    font-size: 6.4rem;
  }
`

const Paragraph = styled(Text)`
  margin-bottom: 32px;
  line-height: 1.45em;

  @media (min-width: ${BREAKPOINT.medium}px) {
    margin-bottom: 40px;
  }
`

const CountersWrapper = styled.div`
  padding-bottom: 40px;

  @media (min-width: ${BREAKPOINT.large}px) {
    padding-bottom: 50px;
  }
`

export default function Hero() {
  const { t } = useTranslation('landing')

  return (
    <Container>
      <StyledOval fill={COLORS.white} />
      <Grid pad>
        <StyledColumn>
          <Inner>
            <header>
              <Caption forwardedAs="h1" weight="bold" align="center">
                {t('valueProposition')}
              </Caption>
            </header>
            <Paragraph size="large" align="center">
              {t('valuePropText')}
            </Paragraph>
            <Button large as="div">
              <Link href="/auth">
                <a>{t('connect')}</a>
              </Link>
            </Button>
          </Inner>
        </StyledColumn>
        <StyledColumn>
          <CountersWrapper>
            <Counters />
          </CountersWrapper>
        </StyledColumn>
      </Grid>
    </Container>
  )
}

import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import Column from '../../components/Column'
import Grid from '../../components/Grid'
import Text from '../../components/Text'
import useInView from '../../hooks/useInView'
import { BREAKPOINT } from '../app/constants'

import Partner from './Partner'

const Container = styled.section`
  padding: 45px 0 64px;

  @media (min-width: ${BREAKPOINT.medium}px) {
    padding: 75px 0 84px;
  }
`

const TextColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.header`
  display: flex;
  justify-content: center;
  padding-bottom: 3.2rem;
`

const SubHeader = styled.div`
  margin-bottom: 50px;
  max-width: 620px;

  @media (min-width: ${BREAKPOINT.large}px) {
    margin-bottom: 100px;
  }
`

const PartnersRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${BREAKPOINT.large}px) {
    margin: 0 -35px;
  }
`

const PartnersCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 120px;
  margin-bottom: 15px;
  padding: 0 15px;

  @media (min-width: ${BREAKPOINT.large}px) {
    padding: 0 35px;
  }
`

export default function Partners() {
  const { t } = useTranslation('landing')

  const { ref, inView } = useInView({
    unobserveOnEnter: true,
  })

  return (
    <Container>
      <Grid pad>
        <TextColumn>
          <Header>
            <Text as="h1" size="giant" weight="bold" align="center">
              {t('ourPartners')}
            </Text>
          </Header>
          <SubHeader>
            <Text size="large" align="center">
              {t('partnersText')}
            </Text>
          </SubHeader>
        </TextColumn>
      </Grid>
      <Grid pad>
        <Column>
          <PartnersRow ref={ref}>
            <PartnersCol>
              <Partner image="swedishjobtech" animate={inView} index={0} />
            </PartnersCol>
          </PartnersRow>
        </Column>
      </Grid>
    </Container>
  )
}

import PropTypes from 'prop-types'
import styled from 'styled-components'

import Text from '../../components/Text'
import { COLORS } from '../../utils/colorUtils'

const Container = styled.figure`
  display: flex;
  align-items: flex-start;
  padding: 18px 40px 18px 18px;
  border-radius: 8px;
  background-color: ${COLORS.paleSlate};

  & + & {
    margin-top: 9px;
  }
`

const Avatar = styled.div`
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${COLORS.white};
`

const Name = styled(Text)`
  color: ${COLORS.secondary};
`

const BlockQuote = styled.blockquote`
  display: flex;
  flex-grow: 1;
  flex-direction: column-reverse;
  margin-top: -3px;
`

const QuoteText = styled(Text)`
  padding-top: 4px;
`

export default function Quote({ name, quote }) {
  return (
    <Container>
      <Avatar />
      <BlockQuote>
        <QuoteText size="atomic">&ldquo;{quote}&rdquo;</QuoteText>
        <figcaption>
          <Name forwardedAs="cite" size="micro" weight="bold">
            {name}
          </Name>
        </figcaption>
      </BlockQuote>
    </Container>
  )
}

Quote.propTypes = {
  name: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
}

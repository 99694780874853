import { useEffect } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Router from 'next/router'

import Layout from '../features/layout/Layout'
import { PAGE_TITLES } from '../features/app/constants'
import Hero from '../features/landing/Hero'
import Partners from '../features/landing/Partners'
import Functions from '../features/landing/Functions'
import Reviews from '../features/landing/Reviews'
import Cta from '../features/landing/Cta'
import useFirebase from '../hooks/useFirebase'

export default function LandingPage() {
  // TODO: remove next line when we want to show landing page
  const { isInitializing, isAuthenticated } = useFirebase()

  // TODO: remove useEffect block when we want to show landing page
  useEffect(() => {
    if (!isInitializing && isAuthenticated) {
      Router.replace('/profile')
    } else if (!isInitializing && !isAuthenticated) {
      Router.replace('/auth')
    }
  }, [isInitializing, isAuthenticated])

  // TODO: remove next line when we want to show landing page
  return null

  return (
    <Layout title={PAGE_TITLES.landing}>
      <Hero />
      <Partners />
      <Functions />
      <Reviews />
      <Cta />
    </Layout>
  )
}

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'landing', 'terms'])),
  },
})

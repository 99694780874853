import { memo } from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'
import styled, { css, keyframes } from 'styled-components'

const animation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
`

const Container = styled.figure`
  width: 100%;
  opacity: 0;
  transform: translateY(100%);
  ${props =>
    props.animate
      ? css`
          animation: ${animation} 1s ease forwards;
          animation-delay: ${(props.index + 1) * 180}ms;
        `
      : ''}
`

function Partner({ image, animate, index }) {
  return (
    <Container animate={animate} index={index}>
      <Image
        src={`/static/images/${image}.png`}
        width={500}
        height={500}
        alt={image}
      />
    </Container>
  )
}

Partner.propTypes = {
  image: PropTypes.string.isRequired,
  animate: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
}

export default memo(Partner)

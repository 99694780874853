import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { COLORS } from '../../utils/colorUtils'
import Grid from '../../components/Grid'
import Column from '../../components/Column'
import Text from '../../components/Text'
import Button from '../../components/Button'
import { BREAKPOINT } from '../app/constants'

const Container = styled.section`
  padding: 80px 0;
  background-color: ${COLORS.black};

  @media (min-width: ${BREAKPOINT.medium}px) {
    padding: 120px 0;
  }
`

const Title = styled(Text)`
  margin-bottom: 22px;
  font-size: 3.6rem;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Paragraph = styled(Text)`
  margin-bottom: 24px;
  max-width: 425px;
`

export default function Cta() {
  const { t } = useTranslation('landing')

  return (
    <Container>
      <Grid pad>
        <Column>
          <Title
            forwardedAs="h1"
            weight="bold"
            align="center"
            color={COLORS.white}
          >
            {t('finishUserWithCta')}
          </Title>
          <Inner>
            <Paragraph size="large" align="center" color={COLORS.white}>
              {t('lorem')}
            </Paragraph>
            <Button large inverted as="div">
              <Link href="/auth">
                <a>{t('registerNow')}</a>
              </Link>
            </Button>
          </Inner>
        </Column>
      </Grid>
    </Container>
  )
}
